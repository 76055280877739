import React from 'react'
import NavigationBar from './NavigationBar'
import { Container } from 'react-bootstrap'
import Footer from './Footer'

export default function PrivacyPolicy() {
    return (
        <div>
            <NavigationBar />
            <Container>
                <h1 style={{
                    marginTop:"3rem"
                }}>Privacy Policy</h1>
                <p>This Privacy Policy (“Privacy Policy”) applies to Gujju Stream App platforms including without limitation Gujju Stream app and other related App/s, mobile applications and other online features (each a “Site/s or App/s”).</p>

                <p>This Privacy Policy should be read in conjunction with the Terms of Use available on the “Site/s or App/s.” Personal Information/Data defined below of a user/s is collected if the user/s registers with the Site/s or App/s, accesses the Site/s or App/s or takes any action on the Site/s or App/s or uses or accesses the Services as defined in the Terms of Use. The terms ‘Personal Information’ and ‘Sensitive Personal Information or Information’ shall have the meaning ascribed to it under the Indian Information Technology Act, 2000, read with the Information Technology.</p>

                <h2>WHAT DO WE DO WITH YOUR INFORMATION?</h2>
                <p>When you subscribe to something from our packages as part of the buying and selling process, we collect the personal information you give us such as your name, address, and email address.</p>

                <p>When you browse our packages, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system. Email marketing (if applicable): With your permission, we may send you emails about our store, new products, and other updates.</p>

                <h2>CONSENT</h2>
                <p>When you provide us with personal information to complete a transaction or verify your credit card, we imply that you consent to our collecting it and using it for that specific reason only.</p>

                <p>If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent or provide you with an opportunity to say no.</p>

                <h2>HOW DO I WITHDRAW MY CONSENT?</h2>
                <p>If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by emailing us at <a href="mailto:gujjustream2024@gmail.com">gujjustream2024@gmail.com</a>.</p>

                <h2>DISCLOSURE</h2>
                <p>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</p>

                <h2>PAYMENT</h2>
                <p>We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction.</p>

                <p>After that is complete, your purchase transaction information is not saved. Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers. For more insight, you may also want to read the terms and conditions of Razorpay at <a href="https://razorpay.com" target="_blank">https://razorpay.com</a>.</p>

                <h2>THIRD-PARTY SERVICES</h2>
                <p>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.</p>

                <p>However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.</p>

                <p>For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.</p>

                <p>In particular, remember that certain providers may be located in or have facilities that are located at different jurisdictions than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.</p>

                <p>Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.</p>

                <h2>LINKS</h2>
                <p>When you click on links on our website for subscription, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>

                <h2>SECURITY</h2>
                <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.</p>

                <h2>COOKIES</h2>
                <p>We use cookies to maintain the session of user/s. It is not used to personally identify you on other websites.</p>

                <h2>AGE OF CONSENT</h2>
                <p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>

                <h2>CHANGES TO THIS PRIVACY POLICY</h2>
                <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. If our App is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to provide services to you.</p>

            </Container>
            <Footer/>
        </div>
    )
}
