import React from 'react'

export default function Footer() {
    return (
        <div style={{
            backgroundColor: "#0000002e",
            padding: "10px",
            verticalAlign: "middle",
            display: "flex",
            marginTop: "5rem",
            justifyContent: "center",
            alignItems: "center",
            // position: "absolute",
            // bottom: "0",
            // left: "0",
            // width: "100%",
        }}>
            <p style={{ fontWeight: "bolder",textAlign:"center" }}>

                © 2024 Gujju Stream. Gujju Stream is not the owner of all relevant images and content, and all movies and shows on this platform. <br/>It is uploaded only for the entertainment purpose. created and developed by Gujju Stream.
            </p>
        </div>
    )
}
