import React from 'react'
import NavigationBar from './NavigationBar'
import { Container } from 'react-bootstrap'
import Footer from './Footer'

export default function RefundPolicy() {
    return (
        <div>
            <NavigationBar />
            <Container>
                <h1 style={{ marginTop: "3rem" }}>Refund Policy</h1>
                <p>This Policy applies to Gujju Stream App platforms including without limitation Gujju Stream app and other related Site/s or App/s, mobile applications, and other online features (each a “Site/s or App/s”).</p>

                <p>We have provided extensive information for you to view the packages before choosing to subscribe to us. If you have any questions or reservations, please contact us at <a href="mailto:gujjustream2024@gmail.com">gujjustream2024@gmail.com</a> prior to subscribing to our services.</p>

                <p>We, being the service providers for contents available through the SITE or APP, where you are expected to view packages of your choice after being paid for a subscription; unfortunately, all fees to Gujju Stream for such services are non-refundable.</p>

                <p>In case, because of any technical glitch at the time of an online transaction, the transaction does not occur, the amount in the process of transfer by default goes back into your bank account automatically through the Payment Gateway.</p>

                <h2>CHARGEBACKS</h2>
                <p>If we receive a chargeback or payment dispute from a credit card company or bank, your service and/or subscription will be suspended without notice. An applicable chargeback fee will be issued to recover fees passed on to us by the credit company, plus any outstanding balances accrued as a result of the chargeback(s) must be paid in full before service is restored.</p>

                <p>Instead of issuing a chargeback, contact us to address any billing issues. Requesting a chargeback or opening any sort of dispute for a valid charge from us is fraud, and is never an appropriate or legal means of obtaining a refund.</p>
            </Container>
            <Footer/>
        </div>
    )
}
